.columns-container {
  margin-bottom: 40px;

  .column {
    margin-bottom: 40px;

    h2, h3 {
      text-align: left;
    }
    h2 {
      margin-bottom: 5px;
      margin-top: 0;
    }
    h3 {
      margin-bottom: 10px;
    }
  }
}

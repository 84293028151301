#site-wrapper {
  position: absolute;
  overflow: hidden;
  width: 100%;
  background: #fff;

  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -ms-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;

  &.navigation-open {
    margin-left: -320px;

    @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
      margin-left: -220px;
    }
  }
}

#nav {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;

  &,
  #navigation-block,
  #navigation-block__logo,
  #navigation-block__logo img,
  #navigation-block #navigation-block__nav nav ul li a {
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -ms-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
  }

  &.navigation-sticky {
    background-color: $strong-blue;

    #navigation-block {
      height: 60px;

      &__logo, &__logo img {
        height: 60px;

        &.navbar-logo-color {
          opacity: 0;
        }
        &.navbar-logo-white {
          opacity: 1;
        }
      }

      &__nav-mobile #navigation-hamburger span {
        background: #fff;
      }
    }

    #navigation-block #navigation-block__nav nav ul li a {
      color: #fff;
    }
  }

  #navigation-block {
    @include flexbox();
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    max-width: 1060px;
    margin: 20px auto;
    padding: 0 20px;

    .header-item {

    }

    &__logo {
      height: 80px;

      img {
        height: 80px;
        opacity: 1;
        position: absolute;

        &.navbar-logo-white {
          opacity: 0;
        }
      }
    }

    &__nav {
      nav {
        ul {
          margin: 0;

          li {
            display: inline-block;

            a {
              color: $strong-blue;
              font-weight: 700;
              padding: 10px 40px;
            }
          }
        }
      }
    }

    &__nav-mobile {
      display: none;

      #navigation-hamburger {
        width: 60px;
        height: 45px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
          display: block;
          position: absolute;
          height: 9px;
          width: 100%;
          background: $strong-blue;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out;
        }

        span:nth-child(1) {
          top: 0px;
        }
        span:nth-child(2), span:nth-child(3) {
          top: 18px;
        }
        span:nth-child(4) {
          top: 36px;
        }
        &.open span:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
        &.open span:nth-child(1) {
          top: 18px;
          width: 0%;
          left: 50%;
        }
        &.open span:nth-child(2) {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &.open span:nth-child(3) {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &.open span:nth-child(4) {
          top: 18px;
          width: 0%;
          left: 50%;
        }

        &.navigation-hamburger-white {

        }
        &.navigation-hamburger-blue {

        }
      }
    }
  }
}

.side-nav {
  position: fixed;
  width: 320px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: $strong-blue;
  opacity: .9999;

  nav {
    ul {
      list-style: none;
      margin: 20px 0 0;
      padding: 0;

      li {
        a {
          display: block;
          font-size: 24px;
          color: #fff;
          padding: 10px 20px;
        }
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
    width: 220px;

    nav ul li a {
      display: block;
      font-size: 18px;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, lg)) {
  #nav #navigation-block #navigation-block__nav {
    display: none;
  }
}
@media (max-width: map-get($grid-breakpoints, lg)) {
  #nav #navigation-block #navigation-block__nav-mobile {
    display: block;
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  #nav {
    &.navigation-sticky {
      #navigation-block {
        height: 40px;

        &__logo, &__logo img {
          height: 40px;
        }
      }
    }


    #navigation-block {
      height: 80px;

      &__logo {
        height: 40px;

        img {
          height: 40px;
        }
      }

      &__nav-mobile {
        #navigation-hamburger {
          transform: scale(.5);
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  #nav {
    &.navigation-sticky {
      #navigation-block {
        height: 60px;

        &__logo, &__logo img {
          height: 60px;
        }
      }
    }

    #navigation-block {
      height: 100px;

      &__logo {
        height: 60px;

        img {
          height: 60px;
        }
      }

      &__nav-mobile {
        #navigation-hamburger {
          transform: scale(.5);
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  #nav {
    #navigation-block {
      &__nav-mobile {
        #navigation-hamburger {
          transform: scale(.5);
        }
      }
    }
  }
}

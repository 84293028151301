.infographic-members-container {
  .infographic-members {
    background: url('/images/infographic-heads.svg') no-repeat;
    background-size: contain;

    .infographic-members-text-container {
      float: right;

      .infographic-members-number p, .infographic-members-text p {
        color: $strong-blue;
        text-align: center;
        font-weight: 900;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .infographic-members-container {
    margin-top: 0px;

    .infographic-members {
      height: 320px;
      background-size: 400px;
      background-position: center;

      .infographic-members-text-container {
        width: 245px;
        height: 120px;
        margin: 106px 0 0 -113px;
        position: absolute;
        left: 50%;

        .infographic-members-number p {
          font-size: 42px;
          line-height: 24px;
        }
        .infographic-members-text p {
          font-size: 24px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .infographic-members-container {
    margin-top: 64px;

    .infographic-members {
      height: 340px;
      background-position: center;

      .infographic-members-text-container {
        width: 245px;
        height: 120px;
        margin: 96px 0 0 -113px;
        position: absolute;
        left: 50%;

        .infographic-members-number p {
          font-size: 64px;
          line-height: 48px;
        }
        .infographic-members-text p {
          font-size: 30px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .infographic-members-container {
    margin-top: 64px;

    .infographic-members {
      height: 450px;
      background-position: center;

      .infographic-members-text-container {
        width: 314px;
        height: 159px;
        margin: 130px 180px 0 0;

        .infographic-members-number p {
          font-size: 72px;
          line-height: 64px;
          margin-bottom: 0;
        }
        .infographic-members-text p {
          font-size: 36px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .infographic-members-container {
    margin-top: -144px;

    .infographic-members {
      height: 347px;

      .infographic-members-text-container {
        width: 260px;
        height: 130px;
        margin: 110px 80px 0 0;

        .infographic-members-number p {
          font-size: 64px;
          line-height: 48px;
        }
        .infographic-members-text p {
          font-size: 32px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .infographic-members-container {
    margin-top: -144px;

    .infographic-members {
      height: 425px;

      .infographic-members-text-container {
        width: 305px;
        height: 140px;
        margin: 130px 110px 0 0;

        .infographic-members-number p {
          font-size: 64px;
          line-height: 48px;
        }
        .infographic-members-text p {
          font-size: 36px;
        }
      }
    }
  }
}

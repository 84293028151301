.percentage-boxes-container {
  &.percentage-boxes-container-nopadding .percentage-box-container:last-child {
    margin-bottom: 0;
  }

  .percentage-box-container {
    margin: 20px 0;
    height: 160px;
    @include flexbox();

    .percentage-box {
      @include percentage-box-gradient(1, 1, 1);
      position: relative;
      padding: 20px 30px;
      @include flexbox();
      flex-direction: row;
      align-items: center;
      width: 100%;

      .percentage-box-background {
        background: #fff;
        top: 4px;
        right: 4px;
        bottom: 4px;
        left: 4px;
        position: absolute;

        -webkit-transition: all .5s ease-in-out;
        -moz-transition: all .5s ease-in-out;
        -ms-transition: all .5s ease-in-out;
        -o-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
      }

      .percentage-box-percentage-container, .percentage-box-text-container, .percentage-box-image-container {
        opacity: .9999;
        z-index: 1;

        p {
          margin: 0;
        }
      }

      .percentage-box-percentage-container {
        p {
          font-size: 30px;
          margin-right: 20px;
        }
      }

      .percentage-box-text-container {
        p {
          font-size: 20px;
        }
      }

      .percentage-box-image-container {
        img {

        }
      }
    }

    &.percentage-box-container-gradient {
      .percentage-box-background {
        width: 100%;
        left: initial;
      }
      .percentage-box-percentage-container, .percentage-box-text-container, .percentage-box-image-container {
        p {
          color: #fff;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .percentage-boxes-container {

    .percentage-box-container {
      margin: 20px 0;
      height: 160px;
      @include flexbox();

      .percentage-box {
        display: block;
        padding: 15px;

        .percentage-box-percentage-container {
          p {
            font-size: 18px;
            font-weight: 900;
            float: left;
            margin-right: 5px;
          }
        }

        .percentage-box-text-container {
          p {
            font-size: 18px;
            margin-right: 5px;
          }
        }

        .percentage-box-image-container {
          display: none;
        }
      }
    }
  }
}

.infographic-board-container {


  .infographic-board {
    background: url('/images/infographic-board.svg') no-repeat;
    background-size: contain;
    height: 541px;

    .infographic-board-text-container {
      float: right;

      .infographic-board-number p, .infographic-board-text p {
        color: #fff;
        text-align: center;
        font-weight: 900;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .infographic-board-container {
    margin-bottom: 64px;

    .infographic-board {
      background-size: 320px;
      background-position: center;
      height: 370px;

      .infographic-board-text-container {
        width: 149px;
        height: 194px;
        left: 50%;
        position: absolute;
        margin: 40px 0 0 5px;
      }

      .infographic-board-number p {
          font-size: 60px;
      }
      .infographic-board-text {
        margin-top: -10px;

        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .infographic-board-container {
    margin-bottom: 64px;

    .infographic-board {
      height: 545px;
      background-position: center;

      .infographic-board-text-container {
        width: 42%;
        height: 54.5%;
        margin: 9% 7% 0 0;
      }

      .infographic-board-number p {
          font-size: 120px;
      }
      .infographic-board-text {
        margin-top: -32px;

        p {
          font-size: 32px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .infographic-board-container {
    margin-bottom: 64px;

    .infographic-board {
      background-position: center;

      .infographic-board-text-container {
        width: 229px;
        height: 297px;
        margin: 48px 111px 0 0;
      }

      .infographic-board-number p {
        font-size: 120px;
      }
      .infographic-board-text {
        margin-top: -26px;

        p {
          font-size: 32px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .infographic-board-container {
    margin-top: 64px;

    .infographic-board {
      background-position: initial;

      .infographic-board-text-container {
        width: 205px;
        height: 266px;
        margin: 43px 10px 0 0;
      }

      .infographic-board-number p {
        font-size: 100px;
      }
      .infographic-board-text {
        margin-top: -26px;

        p {
          font-size: 26px;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .infographic-board-container {
    margin-top: 64px;

    .infographic-board {
      .infographic-board-text-container {
        width: 229px;
        height: 298px;
        margin: 47px 61px 0 0;
      }

      .infographic-board-number p {
        font-size: 120px;
      }
      .infographic-board-text {
        margin-top: -26px;

        p {
          font-size: 32px;
        }
      }
    }
  }
}

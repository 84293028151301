.infographic-rocket-container {
  .infographic-rocket {
    background: url('/images/infographic-rocket.svg') no-repeat;
    background-size: contain;
  }

  .infographic-rocket-number p, .infographic-rocket-text p {
    color: $strong-blue;
    text-align: center;
    font-weight: 900;
  }
  .infographic-rocket-number {
    &:before {
      content: url('/images/infographic-sparkles-left.svg');
      position: absolute;
    }

    &:after {
      content: url('/images/infographic-sparkles-right.svg');
      float: right;
    }

    p {
        font-size: 120px;
        line-height: 120px;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .infographic-rocket-container {
    margin-top: 64px;

    .infographic-rocket {
      height: 297px;
      background-position: center;
    }

    .infographic-rocket-number {
      &:before {
        margin: 30px 0 0 15%;
      }

      &:after {
        margin: -105px 15% 0 0;
      }

      p {
          font-size: 120px;
          line-height: 120px;
      }
    }

    .infographic-rocket-text {

      p {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .infographic-rocket-container {
    margin-top: 64px;

    .infographic-rocket {
      height: 297px;
      background-position: center;
    }

    .infographic-rocket-number {
      &:before {
        margin: 30px 0 0 25%;
      }

      &:after {
        margin: -105px 25% 0 0;
      }

      p {
          font-size: 120px;
          line-height: 120px;
      }
    }

    .infographic-rocket-text {

      p {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .infographic-rocket-container {
    .infographic-rocket {
      height: 297px;
      background-position: center;
    }

    .infographic-rocket-number {
      &:before {
        margin: 30px 0 0 25%;
      }

      &:after {
        margin: -105px 25% 0 0;
      }

      p {
          font-size: 120px;
          line-height: 120px;
      }
    }

    .infographic-rocket-text {

      p {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .infographic-rocket-container {
    .infographic-rocket {
      height: 185px;
    }

    .infographic-rocket-number {
      &:before {
        margin: 10px 0 0 30px;
      }

      &:after {
        margin: -85px 30px 0 0;
      }

      p {
          font-size: 80px;
          line-height: 80px;
      }
    }

    .infographic-rocket-text {

      p {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .infographic-rocket-container {
    .infographic-rocket {
      height: 237px;
    }

    .infographic-rocket-number {
      &:before {
        margin: 30px 0 0 40px;
      }

      &:after {
        margin: -105px 45px 0 0;
      }

      p {
          font-size: 120px;
          line-height: 120px;
      }
    }

    .infographic-rocket-text {

      p {
        font-size: 32px;
        line-height: 42px;
      }
    }
  }
}

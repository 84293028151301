.editorial-signature {
  @include flexbox();
  flex-direction: row;
  padding: 30px 40px 60px;

  .editorial-image-container {
    padding-right: 40px;
  }
  .editorial-text-container {
    @include flexbox();
    flex-direction: column;
    justify-content: center;

    h2, h3 {
      margin: 2px 0;
      text-align: left;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .editorial-signature {
    padding: 0 10px 60px;

    .editorial-image-container {
      padding: 0 10px 10px;
      transform: scale(.75);
    }
    .editorial-text-container {
      @include flexbox();
      flex-direction: column;
      justify-content: center;
    }
  }
}

.partners-container {
  margin-bottom: 60px;

  .section-title {
    margin-bottom: 20px;
  }

  .members-container {
    margin: 20px 0 40px;

    > div {
      @include flexbox();
      align-items: center;
      justify-content: center;

      img {
        max-height: 150px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .partners-container {
    .members-container {
      > div {
        margin: 20px 0;
      }
    }
  }
}

@mixin horizontal-gradient($greenOpacity, $purpleOpacity) {
  background-image: -webkit-linear-gradient(left, rgba($wintergreen, $greenOpacity), rgba($easter-purple, $purpleOpacity));
  background-image: -o-linear-gradient(left, rgba($wintergreen, $greenOpacity), rgba($easter-purple, $purpleOpacity));
  background-image: linear-gradient(to right, rgba($wintergreen, $greenOpacity), rgba($easter-purple, $purpleOpacity));
}

@mixin vertical-gradient($greenOpacity, $purpleOpacity) {
  background-image: -webkit-linear-gradient(bottom, rgba($wintergreen, $greenOpacity), rgba($easter-purple, $purpleOpacity));
  background-image: -o-linear-gradient(bottom, rgba($wintergreen, $greenOpacity), rgba($easter-purple, $purpleOpacity));
  background-image: linear-gradient(to top, rgba($wintergreen, $greenOpacity), rgba($easter-purple, $purpleOpacity));
}

@mixin percentage-box-gradient($greenOpacity, $blueOpacity, $redOpacity) {
  background-image: -webkit-linear-gradient(left, rgba($wintergreen, $greenOpacity), rgba($strong-blue, $blueOpacity) 51%, rgba($carnation, $redOpacity));
  background-image: -o-linear-gradient(left, rgba($wintergreen, $greenOpacity), rgba($strong-blue, $blueOpacity) 51%, rgba($carnation, $redOpacity));
  background-image: linear-gradient(to right, rgba($wintergreen, $greenOpacity), rgba($strong-blue, $blueOpacity) 51%, rgba($carnation, $redOpacity));
}

@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

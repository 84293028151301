@-webkit-keyframes movePeople {
  from{
    -webkit-transform : translateX(0);
    transform         : translateX(0);
  }
  to{
    -webkit-transform : translateX(-2560px);
    transform         : translateX(-2560px);
  }
}

@keyframes movePeople {
  from{
    -webkit-transform : translateX(0);
    transform         : translateX(0);
  }
  to{
    -webkit-transform : translateX(-2560px);
    transform         : translateX(-2560px);
  }
}

@-webkit-keyframes moveClouds {
  from{
    -webkit-transform : translateX(0);
    transform         : translateX(0);
  }
  to{
    -webkit-transform : translateX(-2609px);
    transform         : translateX(-2609px);
  }
}

@keyframes moveClouds {
  from{
    -webkit-transform : translateX(0);
    transform         : translateX(0);
  }
  to{
    -webkit-transform : translateX(-2609px);
    transform         : translateX(-2609px);
  }
}

@-webkit-keyframes moveMountains {
  from{
    -webkit-transform : translateX(0);
    transform         : translateX(0);
  }
  to{
    -webkit-transform : translateX(-2602px);
    transform         : translateX(-2602px);
  }
}

@keyframes moveMountains {
  from{
    -webkit-transform : translateX(0);
    transform         : translateX(0);
  }
  to{
    -webkit-transform : translateX(-2602px);
    transform         : translateX(-2602px);
  }
}

@-webkit-keyframes floatingObjects {
  from {
    -moz-transform: translateX(0px) translateY(0px);
    -webkit-transform: translateX(0px) translateY(0px);
    -o-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
  50% {
    -moz-transform: translateX(0px) translateY(15px);
    -webkit-transform: translateX(0px) translateY(15px);
    -o-transform: translateX(0px) translateY(15px);
    -ms-transform: translateX(0px) translateY(15px);
    transform: translateX(0px) translateY(15px);
  }
  to {
    -moz-transform: translateX(0px) translateY(-0px);
    -webkit-transform: translateX(0px) translateY(-0px);
    -o-transform: translateX(0px) translateY(-0px);
    -ms-transform: translateX(0px) translateY(-0px);
    transform: translateX(0px) translateY(-0px);
  }
}

@keyframes floatingObjects {
  from {
    -moz-transform: translateX(0px) translateY(0px);
    -webkit-transform: translateX(0px) translateY(0px);
    -o-transform: translateX(0px) translateY(0px);
    -ms-transform: translateX(0px) translateY(0px);
    transform: translateX(0px) translateY(0px);
  }
  50% {
    -moz-transform: translateX(0px) translateY(15px);
    -webkit-transform: translateX(0px) translateY(15px);
    -o-transform: translateX(0px) translateY(15px);
    -ms-transform: translateX(0px) translateY(15px);
    transform: translateX(0px) translateY(15px);
  }
  to {
    -moz-transform: translateX(0px) translateY(-0px);
    -webkit-transform: translateX(0px) translateY(-0px);
    -o-transform: translateX(0px) translateY(-0px);
    -ms-transform: translateX(0px) translateY(-0px);
    transform: translateX(0px) translateY(-0px);
  }
}

.infographic-funds-container {
  .infographic-funds {
    background: url('/images/infographic-funds.svg') no-repeat;
    background-size: contain;

    .infographic-funds-number {
      float: right;
      @include flexbox();
      justify-content: center;
      align-items: center;
    }
  }

  .infographic-funds .infographic-funds-number p, .infographic-funds-text p {
    color: $royal;
    text-align: center;
    font-weight: 900;
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  .infographic-funds-container {
    .infographic-funds {
      height: 270px;
      background-size: 320px;
      background-position: center;

      .infographic-funds-number {
        width: 131px;
        height: 130px;
        margin: 103px 0 0 -41px;
        left: 50%;
        position: absolute;

        p {
            font-size: 72px;
            margin-top: 10px;
        }
      }
    }

    .infographic-funds-text {
      margin-top: 0px;

      p {
        font-size: 24px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
  .infographic-funds-container {
    .infographic-funds {
      height: 280px;
      background-position: center;

      .infographic-funds-number {
        width: 26%;
        height: 50%;
        margin: 20% 32% 0 0;

        p {
            font-size: 72px;
            margin-top: 10px;
        }
      }
    }

    .infographic-funds-text {
      margin-top: 20px;

      p {
        font-size: 32px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg)) {
  .infographic-funds-container {
    .infographic-funds {
      height: 280px;
      background-position: center;

      .infographic-funds-number {
        width: 134px;
        height: 134px;
        margin: 108px 252px 0 0;

        p {
            font-size: 72px;
            margin-top: 10px;
        }
      }
    }

    .infographic-funds-text {
      p {
        font-size: 32px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl)) {
  .infographic-funds-container {
    .infographic-funds {
      height: 190px;

      .infographic-funds-number {
        width: 84px;
        height: 84px;
        margin: 62px 46px 0 0;

        p {
            font-size: 56px;
            margin-top: 10px;
        }
      }
    }

    .infographic-funds-text {
      p {
        font-size: 32px;
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .infographic-funds-container {
    .infographic-funds {
      height: 237px;

      .infographic-funds-number {
        width: 104px;
        height: 104px;
        margin: 80px 56px 0 0;

        p {
            font-size: 72px;
            margin-top: 10px;
        }
      }
    }

    .infographic-funds-text {
      p {
        font-size: 32px;
      }
    }
  }
}

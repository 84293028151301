.timeline-container-desktop {
  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
    display: none;
  }
}

.timeline-container-mobile {
  display: none;

  .timeline-container-mobile-header {
    background-color: #fff;
    margin-bottom: 36px;
    z-index: 10;

    &.timeline-header-sticky {
      position: fixed;
      top: 80px;
      width: 100%;

      @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
        top: 100px;
      }
    }

    > .timeline-container-mobile-header-background {
      @include horizontal-gradient(.15, .15);

      > .container > .row {
        @include flexbox();
        align-items: center;

        .timeline-button {
          cursor: pointer;
        }

        h1.timeline-button.timeline-prev-button, h1.section-title, h1.timeline-button.timeline-next-button {
          font-size: 16px;
          margin: 0;
          padding: 32px 0;
        }

        h1.timeline-button.timeline-prev-button, h1.timeline-button.timeline-next-button {
          text-overflow: ellipsis;
          color: $periwinkle-blue;
          text-transform: uppercase;

          &.timeline-button-disabled {
            visibility: hidden;
          }

          &.timeline-button-xs {
            display: block;
          }
          &.timeline-button-sm {
            display: none !important;
          }

          @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
            &.timeline-button-xs {
              display: none;
            }
            &.timeline-button-sm {
              display: block !important;
            }
          }
        }

        h1.timeline-button.timeline-prev-button {
          text-align: left;

          &:before {
            content: url('/images/arrow-left-light-blue.svg');
            display: inline-block;
            margin-right: 10px;
          }
        }

        h1.section-title {
          color: $periwinkle-blue;
          font-weight: normal;
          text-align: center;
        }

        h1.timeline-button.timeline-next-button {
          text-align: right;

          &:after {
            display: inline-block;
            margin-left: 10px;
            content: url('/images/arrow-right-light-blue.svg');
            float: right;
          }
        }
      }
    }
  }

  #timeline-container-mobile {
    &.timeline-container-sticky {
      margin-top: 130px;

      @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
        margin-top: 115px;
      }
    }

    .timeline-view-mobile {
      display: none;

      &.timeline-active-mobile {
        display: block;
      }

      h1.timeline-month {
        text-align: left;
        font-size: 36px;
        margin: 8px 0 20px;

        @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
          font-size: 24px;
        }
      }

      .timeline-element-mobile {
        .timeline-element-img {
          height: 150px;
          width: 100%;
          background-repeat: no-repeat;
          background-size: cover;

          @media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md)) {
            height: 200px;
          }
        }

        h3.timeline-element-date, h2.timeline-element-title {
          text-align: left;
        }

        h3.timeline-element-date {
          margin-bottom: 16px;
          margin-top: 0;
        }

        h2.timeline-element-title {
          font-size: 24px;
          margin: 0 0 16px;
        }

        p.timeline-element-text {
          @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
            font-size: 16px;
          }
        }
      }
    }
  }


  @media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)) {
    display: block;
  }
}

.timeline-container {
  height: auto;
  padding-bottom: 100px;
  overflow: hidden;

  .timeline-line-container {
    position: absolute;
    height: 100%;
    width: 80px;
    @include flexbox();
    left: 50%;
    margin-left: -40px;
    justify-content: center;
    opacity: .5;

    .timeline-line {
      @include vertical-gradient(1, 1);
      width: 20px;
      margin: 70px 0;
    }

    .timeline-line-button-container {
      height: 100%;
      @include flexbox();
      width: 80px;
      position: absolute;
      left: 50%;
      margin-left: -40px;

      .timeline-line-button {
        width: 80px;
        height: 80px;
        border-radius: 40px;
        cursor: pointer;
        @include flexbox();
        justify-content: center;
        align-items: center;

        &.timeline-line-button-disabled {
          cursor: initial;

          img {
            opacity: .3;
          }
        }

        &.timeline-line-button-up {
          background: $easter-purple;
          position: absolute;

          img {
            margin-top: -5px;
          }
        }
        &.timeline-line-button-down {
          background: $wintergreen;
          align-self: flex-end;

          img {
            -moz-transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            transform: rotate(180deg);
            margin-top: 5px;
          }
        }

        img {
          width: 40px;
        }
      }
    }
  }

  .timeline-view-container {
    margin-top: 80px;

    .timeline-view {
      display: none;

      &.timeline-active {
        @include flexbox();
      }

      .timeline-left {
        .timeline-element {

          .timeline-element-date, .timeline-element-title, .timeline-element-text, .timeline-element-url-container {
            text-align: right;
          }

          &.timeline-element-small, &.timeline-element-medium {
            .timeline-element-img {
              margin: 0 0 20px 20px;
              float: right;
            }
          }
        }
      }
      .timeline-right {
        .timeline-element {
          .timeline-element-date, .timeline-element-title, .timeline-element-text, .timeline-element-url-container {
            text-align: left;
          }

          &.timeline-element-small, &.timeline-element-medium {
            .timeline-element-img {
              margin: 0 20px 20px 0;
              float: left;
            }
          }
        }
      }

      .timeline-left, .timeline-right {
        .timeline-element {
          margin: 20px 0;

          &.timeline-element-small {
            h2.timeline-element-title {
              font-size: 20px;
            }
            .timeline-element-img {
              width: 175px;
              height: 175px;
            }
          }

          &.timeline-element-medium {
            h2.timeline-element-title {
              font-size: 24px;
            }
            .timeline-element-img {
              width: 175px;
              height: 175px;
            }
          }

          &.timeline-element-large {
            .timeline-element-img {
              margin-bottom: 20px;
              width: 100%;
              height: 250px;
            }
          }

          &.timeline-element-heading {
            h1.timeline-element-title {
              color: $wintergreen;
              font-size: 30px;
              font-weight: bold;
            }
          }

          &.timeline-element-divider {
            margin: 20px 0;
          }

          .timeline-element-img {
            background-repeat: no-repeat;
            background-size: cover;
          }
          h3.timeline-element-date, h2.timeline-element-title {

          }
          h3.timeline-element-date {
            margin: 0;
          }
          h1.timeline-element-title {
            margin: 0;
          }
          h2.timeline-element-title {
            margin: 10px 0;
          }
          p.timeline-element-text {

          }
          p.timeline-element-url-container {
            a.timeline-element-url {

            }
          }
        }
      }
    }
  }
}

footer {
  background-color: $strong-blue;

  #footer-block {
    @include flexbox();
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;

    .header-item {

    }

    &__logo {
      img {
        height: 50px;
      }
    }

    &__arrow {
      img {
        cursor: pointer;
        margin: 0;
        padding: 20px;
      }
    }

    &__nav {
      display: none;
      width: 100%;

      nav {
        ul {
          margin: 0;
          @include flexbox();
          flex-direction: row;
          justify-content: space-around;

          li {
            @include flexbox();
            justify-content: center;
            align-items: center;

            p, a {
              color: #fff;
              font-weight: 500;
              padding: 10px 40px;
              font-size: 14px;
              margin: 0;
              text-align: center;
            }

            p {
              font-weight: 700;
            }
          }
        }
      }

      @media (min-width: map-get($grid-breakpoints, lg)) {
        display: block;
      }
    }
  }
}

.btn-outline-primary {
  color: $strong-blue;
  border-color: $strong-blue;
  border-width: 2px;
  border-radius: 0;
  font-weight: 700;
  font-size: 20px;
  text-transform: uppercase;

  &:active, &:hover, &:focus {
    background-color: $strong-blue;
    border-color: $strong-blue;
  }
}

#image-slider {
  width: 100%;
  height: 600px;

  .row {
    height: 100%;

    .image-slider-prev-container, .image-slider-next-container {
      height: 100%;
      padding: 0;
      @include flexbox();
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .image-slider-arrow-background {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: .2;
      }

      .image-slider-arrow {
        width: 53px;
        height: 53px;
        border: 2px solid $strong-blue;
        @include flexbox();
        justify-content: center;

        img {

        }
      }
    }

    .image-slider-prev-container {
      .image-slider-arrow.image-slider-arrow-left {
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }

      .image-slider-arrow-background {
        background-position: right center;
      }
    }

    .image-slider-next-container {
      .image-slider-arrow.image-slider-arrow-right {

      }

      .image-slider-arrow-background {
        background-position: left center;
      }
    }

    .image-slider-current-container {
      height: 100%;
      overflow: hidden;
      padding: 0;

      .image-slider-element {
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        display: none;

        &.image-slider-element-active {
          display: block;
        }
      }
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, sm)) {
  #image-slider {
    height: 300px;
  }
}

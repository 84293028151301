@font-face {
  font-family: CircularPro;
  src: url('/fonts/CircularPro-Book.otf');
  src: url('/fonts/CircularPro-Book.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: CircularPro;
  src: url('/fonts/CircularPro-Bold.otf');
  src: url('/fonts/CircularPro-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: CircularPro;
  src: url('/fonts/CircularPro-Black.otf');
  src: url('/fonts/CircularPro-Black.woff') format('woff');
  font-weight: 900;
}
